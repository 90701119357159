import React, { type FC, useCallback } from 'react';
import classNames from 'classnames';
import { type PaginationControls } from '../../hooks/useRemotePagination';
import DoubleArrowIcon from '../../icons/DoubleArrow';
import Chevron from '../../icons/flip-ui-kit/large/Chevron';
import { SkeletonLine } from '../atoms/LoadingSkeleton';

const paginationButtonStyles =
  'text-white bg-cf-gray-3 rounded-[6px] border border-cf-gray-4 px-2 py-1 cursor-pointer hover:bg-cf-gray-4 hover:border-cf-gray-4';
const disabledStyles =
  'text-cf-gray-5 border-cf-gray-3 hover:bg-cf-gray-3 hover:border-cf-gray-3 hover:cursor-default';

const PageCounter = ({
  paginationControls: props,
  showTotalPages,
}: {
  paginationControls: PaginationControls;
  showTotalPages: boolean;
}) => (
  <div className="flex flex-row items-center">
    <button
      suppressHydrationWarning
      disabled={
        props.useCursor ? !props.paginationCursor.hasPreviousPage : props.pageCounter.atBeginning
      }
      type="button"
      className="p-2 text-white disabled:text-cf-light-3"
      onClick={props.useCursor ? props.paginationCursor.previous : props.pageCounter.decrement}
    >
      <Chevron flip orientation="left-right" />
    </button>
    <p suppressHydrationWarning className="mx-0 text-right text-14 md:mx-4">
      {props.useCursor ? (
        <>Page {props.paginationCursor.pageNumber}</>
      ) : (
        <>
          Page {props.pageCounter.current} {showTotalPages && <>of {props.pageCounter.end}</>}
        </>
      )}
    </p>
    <button
      suppressHydrationWarning
      disabled={props.useCursor ? !props.paginationCursor.hasNextPage : props.pageCounter.atEnd}
      type="button"
      className="p-2 text-white disabled:text-cf-light-3"
      onClick={props.useCursor ? props.paginationCursor.next : props.pageCounter.increment}
    >
      <Chevron orientation="left-right" />
    </button>
  </div>
);

type Alignment = 'left' | 'center' | 'right';

type TableV2Props = {
  columns: (
    | string
    | {
        name: string;
        alignment?: Alignment;
        sortable?: boolean;
        labelHidden?: boolean;
      }
    | {
        key: string;
        children: JSX.Element;
        sortable?: boolean;
      }
  )[];
  rows: JSX.Element[] | null;
  isLoading?: boolean | undefined;
  sorting?: Sort;
  paginationControls?: PaginationControls;
  emptyRowsContent?: React.ReactNode;
  showTotalPages?: boolean;
};

export type Sort = {
  setCol: (index: number) => void;
  sort: {
    col: number;
    direction: 'ASC' | 'DESC';
  };
};

export const TableV2: FC<
  TableV2Props & { className?: string; thClassName?: string; tbodyClassName?: string }
> = ({
  columns,
  rows,
  isLoading,
  paginationControls,
  sorting,
  className,
  thClassName = 'p-4',
  tbodyClassName = '[&>tr>td]:border-cf-gray-3-5',
  emptyRowsContent = 'No activity here yet',
  showTotalPages = true,
}) => {
  const setSort = useCallback(
    (colIndex: number) => {
      paginationControls?.pageCounter.set(1);
      sorting?.setCol(colIndex);
    },
    [paginationControls, sorting],
  );

  return (
    <div className="flex flex-col space-y-8">
      <div className={classNames('h-full w-full overflow-x-auto', isLoading && 'bg-cf-gray-2')}>
        <table
          style={{
            borderCollapse: 'inherit',
          }}
          className={classNames(
            'h-full w-full border-spacing-0 rounded-md border border-cf-gray-3-5 bg-cf-gray-2 group-[.cf-is-panel]:rounded-t-none',
            className,
          )}
        >
          <thead className="border-b border-cf-gray-3-5 text-14 text-cf-light-2">
            <tr>
              {columns.map((column, index) => {
                let name: string | JSX.Element;
                let key: string;
                let alignment: Alignment;
                let sortable: boolean;

                if (typeof column === 'string') {
                  name = column;
                  key = column;
                  alignment = 'left';
                  sortable = Boolean(sorting);
                } else if (typeof column === 'object' && 'children' in column) {
                  name = column.children;
                  key = column.key;
                  alignment = 'left';
                  sortable = column.sortable ?? Boolean(sorting);
                } else {
                  name = column.labelHidden ? '' : column.name;
                  key = column.name;
                  alignment = column.alignment ?? 'left';
                  sortable = column.sortable ?? Boolean(sorting);
                }

                return (
                  <th
                    key={key}
                    className={classNames(
                      'text-left',
                      sortable && 'cursor-pointer',
                      thClassName,
                      'whitespace-nowrap border-b border-cf-gray-3-5 font-aeonikRegular font-normal',
                    )}
                    onClick={() => sortable && setSort(index)}
                  >
                    <span
                      className={classNames(
                        'flex',
                        'items-center',
                        alignment === 'left' && 'justify-start',
                        alignment === 'center' && 'justify-center',
                        alignment === 'right' && 'justify-end',
                      )}
                    >
                      {name}
                      {sortable && (
                        <DoubleArrowIcon
                          className="ml-1 text-cf-light-1"
                          activeColor="white"
                          direction={
                            sorting?.sort?.col === index ? sorting.sort.direction : undefined
                          }
                        />
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className={classNames(
              'text-14 text-cf-light-3 [&>tr:not(:last-child)>td]:border-b [&>tr>td]:p-4',
              tbodyClassName,
            )}
          >
            {isLoading && (
              <tr>
                {columns.map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={index}>
                    <SkeletonLine count={4} className="my-2" />
                  </td>
                ))}
              </tr>
            )}
            {!isLoading && rows && rows?.length > 0
              ? rows
              : !isLoading && (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="my-12 text-center text-14 text-cf-light-3">
                        {emptyRowsContent}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      {paginationControls && (
        <div
          className={classNames(
            'flex flex-col items-center justify-center space-x-4 text-14 md:flex-row md:justify-end',
          )}
        >
          <label className="order-2 text-cf-light-3 md:order-1" htmlFor="pagination">
            Items per page:
            <select
              className="ml-4 cursor-pointer border-b border-cf-gray-5 bg-cf-gray-bg py-2 focus:outline-none"
              id="pagination"
              value={paginationControls.limit}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                paginationControls.setLimit(Number(event.target.value))
              }
            >
              {paginationControls.paginationLimits.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>
          <div className="order-1 flex items-center md:order-2">
            {!paginationControls.useCursor && showTotalPages && (
              <div
                suppressHydrationWarning
                className={classNames(
                  paginationButtonStyles,
                  paginationControls.pageCounter.atBeginning && disabledStyles,
                )}
                onClick={paginationControls.pageCounter.goToBeginning}
              >
                First
              </div>
            )}

            <PageCounter paginationControls={paginationControls} showTotalPages={showTotalPages} />
            {!paginationControls.useCursor && showTotalPages && (
              <div
                suppressHydrationWarning
                className={classNames(
                  paginationButtonStyles,
                  paginationControls.pageCounter.atEnd && disabledStyles,
                )}
                onClick={paginationControls.pageCounter.goToEnd}
              >
                Last
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
